import React from 'react';

class About extends React.Component {
    render() {
        return <div className="About" />;
    }
}

module.exports = {
    path: 'about.html',
    component: About,
};
